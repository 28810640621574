import { queryParameters } from './utilities';
import { pauseAllAutoplayVideos, playAllAutoplayVideos } from './videos';

export function getDark() {
    return localStorage.getItem('dark') === 'true';
}

// Set dark mode and any other meta dark options
export function setDark(dark) {
    // Make sure the value is allowed, otherwise default to false
    const newValue = typeof dark === 'boolean' ? dark : false;
    localStorage.setItem('dark', newValue.toString());
    document.documentElement.classList.toggle('dark', newValue);

    if (newValue) {
        document.querySelector('meta[name=theme-color]')?.setAttribute('content', '#000000');
    } else {
        document.querySelector('meta[name=theme-color]')?.setAttribute('content', '#FFFFFF');
    }
}

// Helper function for if user preferences should be ignored
export function ignoreUserPreferences() {
    // Don't disable autoplay or other motion on signage
    return document.body.classList.contains('sign-wrapper');
}

export function getReducedMotion() {
    return localStorage.getItem('reduced-motion') === 'true';
}

export function setReducedMotion(reducedMotion) {
    const currentValue = getReducedMotion();
    // Make sure the value is allowed, otherwise default to false
    const newValue = typeof reducedMotion === 'boolean' ? reducedMotion : false;
    localStorage.setItem('reduced-motion', newValue.toString());
    document.documentElement.classList.toggle('reduced-motion', newValue);

    // If it's been updated then we need to play or pause all moving elements
    // (note play won't always work since autoplay attribute may have already been stripped)
    if (currentValue !== newValue) {
        if (newValue) {
            pauseAllAutoplayVideos();
        } else {
            playAllAutoplayVideos();
        }
    }
}

// Generic handler for setting up boolean user preference toggles
function setupToggle(name, elements, getter, setter) {
    elements.forEach((el) => {
        if (el.dataset.initialized !== 'true') {
            el.dataset.initialized = 'true'; // Only initialize once...
            // Set initial state
            if (getter()) {
                el.checked = true;
                el.children[0]?.classList.replace('fa-regular', 'fa-solid');
            }

            el.addEventListener('click', () => {
                const newValue = !getter();
                setter(newValue);

                // Update all toggles that may be on the page
                elements.forEach((ele) => {
                    if (newValue) {
                        ele.checked = true;
                        ele.children[0]?.classList.replace('fa-regular', 'fa-solid');
                    } else {
                        ele.checked = false;
                        ele.children[0]?.classList.replace('fa-solid', 'fa-regular');
                    }
                });

                if (useAnalytics) {
                    dataLayer.push({
                        event: 'buttons',
                        label: name,
                        value: value ? 'true' : 'false',
                    });
                }

                return false;
            });
        }
    });
}

export function setupUserPreferences() {
    // In some cases (signage) we want to ignore set user preferences
    if (ignoreUserPreferences()) {
        return;
    }

    const params = queryParameters();

    const savedDark = localStorage.getItem('dark') !== null;
    const defaultDark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    if (params.dark) {
        // First params should override anything else
        setDark(params.dark === 'true');
    } else if (savedDark) {
        // Next anything previously saved should be respected
        setDark(getDark());
    } else {
        // Last default to client preference
        setDark(defaultDark);
    }

    const savedReducedMotion = localStorage.getItem('reduced-motion') !== null;
    const defaultReducedMotion = window.matchMedia && window.matchMedia('(prefers-reduced-motion: reduce)').matches;
    if (params.reducedMotion) {
        // First params should override anything else
        setReducedMotion(params.reducedMotion === 'true');
    } else if (savedReducedMotion) {
        // Next anything previously saved should be respected
        setReducedMotion(getReducedMotion());
    } else {
        // Last default to client preference
        setReducedMotion(defaultReducedMotion);
    }

    setupToggle('dark toggle', document.querySelectorAll('.dark-toggle'), getDark, setDark);
    setupToggle('reduced motion toggle', document.querySelectorAll('.reduced-motion-toggle'), getReducedMotion, setReducedMotion);
}
