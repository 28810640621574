//=================================================
// Guide footer
//=================================================

import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { getDark, getReducedMotion, setDark, setReducedMotion } from '../../user-preferences';
import { getLanguage } from '../utilities';

export default function Footer() {
    const [searchParams] = useSearchParams();
    const language = getLanguage(searchParams);
    const [darkChecked, setDarkChecked] = useState(getDark());
    const [reducedMotionChecked, setReducedMotionChecked] = useState(getReducedMotion());

    function handleDarkClick() {
        const newValue = !darkChecked;
        setDarkChecked(newValue);
        setDark(newValue);

        if (useAnalytics) {
            dataLayer.push({
                event: 'buttons',
                label: 'dark toggle',
                value: newValue ? 'true' : 'false',
            });
        }
    }

    function handleReducedMotionClick() {
        const newValue = !reducedMotionChecked;
        setReducedMotionChecked(newValue);
        setReducedMotion(newValue);

        if (useAnalytics) {
            dataLayer.push({
                event: 'buttons',
                label: 'reduced motion toggle',
                value: newValue ? 'true' : 'false',
            });
        }
    }

    return (
        <footer className="guide-footer">
            <div className="guide-footer__toggles">
                <label htmlFor="dark-mode" className="checkbox">
                    <input id="dark-mode" className="checkbox__input" type="checkbox"
                           checked={ darkChecked }
                           onChange={ handleDarkClick }/>
                    { I18n[language].dark }
                </label>

                <label htmlFor="reduced-motion" className="checkbox">
                    <input id="reduced-motion" className="checkbox__input" type="checkbox"
                           checked={ reducedMotionChecked }
                           onChange={ handleReducedMotionClick }/>
                    { I18n[language].reduced_motion }
                </label>
            </div>

            <div className="guide-footer__list">
                <p className="large m-0">
                    { I18n[language].see_more_on }
                    &nbsp;
                    <a href="/" className="underline">whitney.org</a>
                </p>
            </div>
        </footer>
    );
}
